/*           Labels & Progress-bar              */
.label {
  padding: 0.2em 0.6em;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 3px;
  line-height: 17px;
}
.label-primary {
  background-color: $primary-color;
}
.label-info {
  background-color: $info-color;
}
.label-success {
  background-color: $success-color;
}
.label-warning {
  background-color: $warning-color;
}
.label-danger {
  background-color: $danger-color;
}
.label-default {
  background-color: $default-color;
}
