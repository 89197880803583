code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.z99 {
  z-index: 99;
}

.z0 {
  z-index: 0;
}

.dropdown.show .dropdown-menu[x-placement="bottom-start"] {
  transform: translate3d(0, 60px, 0) !important;
  bottom: auto !important;
  top: 0 !important;
}

@media screen and (max-width: 991px) {
  .dropdown.show .dropdown-menu[x-placement="bottom-start"] {
    position: static !important;
    transform: translate3d(0, 0px, 0) !important;
    bottom: auto !important;
    top: 0 !important;
  }
}

.center {
  text-align: center;
  margin: auto;
  justify-content: center;
  display: flex;
}

.img-circle {
  width: 170px;
  height: 170px;
  object-fit: cover;
}

.img-brand {
  width: 150px;
  object-fit: cover;
}

.border-white-5 {
  border: 5px solid white;
}

.owner .avatar {
  object-fit: cover;
  padding: unset;
  max-width: 180px;
  margin: -85px auto 0;
  display: inline-block;
}

.description {
  font-size: 1.35em;
  line-height: 1.4em;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  color: #403d39 !important;
}

.dropdown-menu .dropdown-item {
  color: #9a9a9a;
  font-weight: 600;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  color: #403d39 !important;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: unset;
}

.profile-content {
  padding-top: 0;
  position: relative;
  z-index: 2;
}

@media (max-height: 850px) {
  .presentation-subtitle {
    margin-bottom: 100px;
  }
}

.navbar.navbar-transparent {
  padding-top: 0;
}

p {
  font-size: 17px;
}

.page-header .content-center {
  margin-top: 165px;
}

.leaflet-container {
  width: 100%;
  height: 35vh;
}

.react-photo-gallery--gallery img {
  border-radius: 4px;
}

.react-photo-gallery--gallery img:hover {
  filter: brightness(110%);
}

.markD strong {
  font-weight: bold;
}

.markD li {
  font-size: 17px;
}

.markD p {
  margin-bottom: 20px;
  text-align: justify;
  text-justify: inter-word;
}

.bg-dark {
  background-color: #0f0e0e !important;
}
