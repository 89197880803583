#cont {
  background-color: #002147;
  padding: 100px;
}

.middle {
  text-align: center;
}

.btn2 {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #f1f1f1;
  margin: 10px;
  border-radius: 30%;
  box-shadow: 0 5px 15px -5px #ecf0f1;
  color: #3498db;
  overflow: hidden;
  position: relative;
}

a.btn2:hover {
  transform: scale(1.2);
  color: #f1f1f1;
  border: 0;
  box-shadow: 0 5px 15px -5px #3c85d5;
}

.btn2 i {
  line-height: 50px;
  font-size: 20px;
  transition: 0.2s linear;
}

.btn2:hover i {
  transform: scale(1.3);
  color: #f1f1f1;
}

.fa-facebook-f {
  color: #3c5a99;
  position: unset;
}

.fa-twitter {
  position: unset;
  color: #1da1f2;
}

.fa-instagram {
  position: unset;
  color: #e1306c;
}


.fa-google {
  position: unset;
  color: #dd4b39;
}

.fa-youtube {
  position: unset;
  color: #ff0000;
}

.btn2::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background: #3498db;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}

.btn2:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}
.btn2 .fa {
  position: relative;
  bottom: 0;
  right: 0;
}
@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    left: -10%;
    top: -10%;
  }
}
